"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { keyBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { useProgress } from "../hooks/use-progress.hook";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/fields/Dropdown.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REVIEWS_ME_INVITE_ROUTE } from "@/lib/routes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ReviewAnswerAPI } from "@/v2/feature/growth/reviews/api-client/review-answer.api";
import { ReviewEntryEndpoints } from "@/v2/feature/growth/reviews/api-client/review-entry.api";
import { MultipleChoiceQuestion } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/multiple-choice-question.component";
import { OpenEndedQuestion } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/open-ended-question.components";
import { ScaleQuestion } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/scale-question.component";
import { SingleAnswerQuestion } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/single-answer-question.component";
import {
  getReviewDueDate,
  getReviewType
} from "@/v2/feature/growth/reviews/features/review-personal/review-entry/review-entry.util";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { EntryState } from "@/v2/feature/growth/reviews/interfaces/review-entry.interface";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReviewAnswerSubmissionPage = () => {
  var _a;
  const params = useParams();
  const { entryId, cycleId } = params;
  const [answerArray, setAnswerArray] = useState(null);
  const [answers, setAnswers] = useState(void 0);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const containerRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showError, setShowError] = useState(false);
  const { data: entryDetail, isValidating: loadingEntryDetail } = useApiClient(
    ReviewEntryEndpoints.getReviewEntryDetail(entryId, cycleId),
    {
      suspense: false
    }
  );
  const getAnswersData = useCallback(() => __async(void 0, null, function* () {
    try {
      const response = yield ReviewAnswerAPI.getAnswer(entryId, cycleId);
      setAnswers(response);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  }), [entryId, cycleId, showMessage]);
  useEffect(() => {
    getAnswersData();
  }, [getAnswersData]);
  const checkForScroll = () => {
    if (containerRef.current) {
      const isContentOverflowing = containerRef.current.scrollHeight > window.innerHeight;
      setShowScrollButton(isContentOverflowing);
    }
  };
  const handleScrollClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth"
      });
      setShowScrollButton(false);
    }
  };
  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;
      setShowScrollButton(!isAtBottom);
    }
  };
  useEffect(() => {
    if (entryDetail && entryDetail.cycle && entryDetail.cycle.state === CycleState.Completed) {
      routerHistory.push(REVIEWS_ME_INVITE_ROUTE);
      showMessage("This cycle has already been completed", "info");
    }
  }, [entryDetail, showMessage, routerHistory]);
  useEffect(() => {
    if (answers) {
      setAnswerArray(answers);
    }
  }, [answers]);
  useEffect(() => {
    setTimeout(() => checkForScroll(), 1500);
    window.addEventListener("resize", checkForScroll);
    return () => window.removeEventListener("resize", checkForScroll);
  }, []);
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  });
  let cycle;
  let sections;
  let questions;
  let entry;
  if (entryDetail) {
    ({ cycle, sections, questions, entry } = entryDetail);
  }
  const questionsLookup = useMemo(() => keyBy(questions != null ? questions : [], "id"), [questions]);
  const reviewType = useMemo(() => entry ? getReviewType(entry) : void 0, [entry]);
  const progressData = useProgress(entryDetail == null ? void 0 : entryDetail.questions, answerArray);
  const isSubmitable = useMemo(() => {
    if (!questions || !answerArray) {
      return false;
    }
    const allQuestionsAnswered = questions.every((q) => {
      var _a2, _b, _c, _d;
      const answer = answerArray.find((a) => a.questionId === q.id);
      if (!answer) return false;
      if (q.type === "openEnded") {
        return answer && answer.comment && ((_b = (_a2 = answer.comment) == null ? void 0 : _a2.trim()) == null ? void 0 : _b.length) > 0;
      } else {
        return answer && answer.answer && ((_d = (_c = answer.answer) == null ? void 0 : _c.trim()) == null ? void 0 : _d.length) > 0;
      }
    });
    const allCommentsValid = questions.every((question) => {
      var _a2, _b;
      if (question.isCommentRequired) {
        const answer = answerArray.find((a) => a.questionId === question.id);
        return answer && answer.comment && ((_b = (_a2 = answer.comment) == null ? void 0 : _a2.trim()) == null ? void 0 : _b.length) > 0;
      }
      return true;
    });
    return allQuestionsAnswered && allCommentsValid;
  }, [answerArray, questions]);
  if (!entry || !cycle || !questions) return /* @__PURE__ */ jsx(Fragment, {});
  const handleAnswerChange = (questionId, updateObject, entry2) => {
    setAnswerArray((prevAnswers) => {
      const updatedAnswers = (prevAnswers == null ? void 0 : prevAnswers.map((answer) => {
        if (answer.questionId === questionId) {
          return __spreadProps(__spreadValues({}, answer), { answer: updateObject.updatedAnswer, comment: updateObject.comment });
        }
        return answer;
      })) || [];
      if (!(prevAnswers == null ? void 0 : prevAnswers.some((answer) => answer.questionId === questionId))) {
        updatedAnswers.push({
          id: v4(),
          cycleId,
          entryId,
          reviewId: entry2.reviewId,
          reviewerId: entry2.reviewerUpwardId || entry2.reviewerManagerId || entry2.reviewerPeerId || entry2.reviewerSelfId,
          answerType: getReviewType(entry2),
          revieweeId: entry2.revieweeId,
          comment: updateObject.comment,
          answer: updateObject.updatedAnswer,
          questionId
        });
      }
      return updatedAnswers;
    });
  };
  const handleSaveDraft = () => __async(void 0, null, function* () {
    try {
      if (answerArray && entry) {
        yield ReviewAnswerAPI.saveAnswersAsDraft(answerArray, entryId, cycleId, entry.revieweeId);
        yield getAnswersData();
        showMessage("Successfully saved as draft", "success");
      }
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  });
  const handleSubmit = () => __async(void 0, null, function* () {
    try {
      if (isSubmitable && answerArray && entry) {
        yield ReviewAnswerAPI.submitAnswers(answerArray, entryId, cycleId, entry.revieweeId);
        routerHistory.push(REVIEWS_ME_INVITE_ROUTE);
        showMessage("Successfully submitted the answers", "success");
      } else {
        setShowError(true);
        showMessage("Please answer all the questions before submitting", "info");
      }
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: ((_a = entryDetail == null ? void 0 : entryDetail.cycle) == null ? void 0 : _a.displayName) || (entryDetail == null ? void 0 : entryDetail.cycle.internalName) }),
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: [
          (entryDetail == null ? void 0 : entryDetail.entry.entryState) !== EntryState.Submitted && /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "secondary", sizeVariant: "small", onClick: () => handleSaveDraft(), children: "Save" }),
          /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "primary", sizeVariant: "small", onClick: () => handleSubmit(), children: (entryDetail == null ? void 0 : entryDetail.entry.entryState) === EntryState.Submitted ? "Resubmit" : "Submit" })
        ] }),
        showAction: cycle.state !== CycleState.Completed,
        sx: { maxWidth: "600px", width: "100%", margin: "0 auto" },
        showBack: true,
        backPath: REVIEWS_ME_INVITE_ROUTE
      }
    ),
    /* @__PURE__ */ jsx(
      ContentWrapper,
      {
        loading: loadingEntryDetail,
        ref: containerRef,
        sx: {
          overflowY: "auto",
          maxHeight: "calc(100vh - 120px)"
        },
        children: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              overflowY: "hidden",
              flexDirection: "column",
              gap: spacing.g16,
              maxWidth: "600px",
              width: "100%",
              margin: "0 auto"
            },
            children: [
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
                /* @__PURE__ */ jsx(
                  ViewItem,
                  {
                    label: "Due date",
                    value: getReviewDueDate(__spreadProps(__spreadValues({}, entry), { cycle })).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" })
                  }
                ),
                (reviewType === ReviewerTypes.Manager || reviewType === ReviewerTypes.Upward || reviewType === ReviewerTypes.Peer) && /* @__PURE__ */ jsx(ViewItem, { label: "You are reviewing", value: /* @__PURE__ */ jsx(UserCell, { userId: entry.revieweeId }) })
              ] }),
              entryDetail == null ? void 0 : entryDetail.cycle.order.map((item, idx) => /* @__PURE__ */ jsx(
                RenderQuestionOrSection,
                {
                  item,
                  questionsLookup,
                  handleAnswerChange,
                  reviewType,
                  sections,
                  cycle,
                  entry,
                  answerArray,
                  showError
                },
                `answer-${idx}`
              )),
              /* @__PURE__ */ jsx(StyledTooltip, { title: `${(progressData == null ? void 0 : progressData.progress) || 0}% done`, children: /* @__PURE__ */ jsx(
                CircularProgress,
                {
                  value: (progressData == null ? void 0 : progressData.progress) || 0,
                  size: 35,
                  color: "success",
                  thickness: 6,
                  variant: "determinate",
                  sx: {
                    position: "fixed",
                    top: "20px",
                    right: "20px",
                    textAlign: "center",
                    color: themeColors.ZeltYellow,
                    borderRadius: "50%",
                    boxShadow: "inset 0 0 0px 4.5px #F6F3EF",
                    backgroundColor: "transparent"
                  }
                }
              ) }),
              showScrollButton && /* @__PURE__ */ jsx(
                IconButton,
                {
                  colorVariant: "primary",
                  sizeVariant: "small",
                  onClick: handleScrollClick,
                  style: { position: "fixed", bottom: "20px", right: "20px", textAlign: "center" },
                  children: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize))
                }
              )
            ]
          }
        )
      }
    )
  ] });
};
const RenderQuestionOrSection = ({
  item,
  questionsLookup,
  handleAnswerChange,
  reviewType,
  sections,
  cycle,
  entry,
  answerArray,
  showError
}) => {
  const renderQuestion = (questionId) => {
    const question = questionsLookup && questionsLookup[questionId];
    if (!question || !cycle) return null;
    return /* @__PURE__ */ jsx(
      QuestionComponent,
      {
        question,
        reviewType,
        answerArray,
        handleAnswerChange,
        entry,
        cycle,
        showError
      },
      question.id
    );
  };
  const renderSection = (sectionId) => {
    var _a;
    const section = sections == null ? void 0 : sections.find((s) => s.id === sectionId);
    if (!section || !cycle) return null;
    const orderSection = cycle == null ? void 0 : cycle.order.find((o) => o.id === sectionId && o.type === "section");
    if (!orderSection) return null;
    const orderedQuestions = ((_a = orderSection.questions) != null ? _a : []).map((questionId) => questionsLookup[questionId]).filter((question) => Boolean(question));
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g16 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: section.name }),
      orderedQuestions.map((q) => /* @__PURE__ */ jsx(
        QuestionComponent,
        {
          question: q,
          reviewType,
          answerArray,
          handleAnswerChange,
          entry,
          cycle,
          showError
        },
        q.id
      ))
    ] });
  };
  const renderQuestionOrSection = () => {
    var _a;
    if (item.type === "question" && questionsLookup[item.id]) {
      return renderQuestion(item.id);
    } else if (item.type === "section" && ((_a = item.questions) != null ? _a : []).some((id) => questionsLookup.hasOwnProperty(id))) {
      return /* @__PURE__ */ jsx(Box, { sx: { marginTop: spacing.m48 }, children: renderSection(item.id) });
    } else return /* @__PURE__ */ jsx(Fragment, {});
  };
  return /* @__PURE__ */ jsx(Fragment, { children: renderQuestionOrSection() });
};
const QuestionComponent = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
  cycle,
  showError
}) => {
  const questionText = useMemo(() => {
    if (!reviewType || !question) return "";
    if (reviewType === ReviewerTypes.Self) return question.questionSelf;
    return question.questionMain;
  }, [reviewType, question]);
  const showHiddenInformation = useMemo(() => {
    if (!cycle || !question || reviewType === ReviewerTypes.Self) return false;
    return true;
  }, [cycle, question, reviewType]);
  const showHiddenInformationTest = useMemo(() => {
    if (!cycle || !question || reviewType === ReviewerTypes.Self || !entry) return /* @__PURE__ */ jsx(Fragment, {});
    const questionVisibility = question.visibilitySettings.hideManagerResult && reviewType === ReviewerTypes.Manager || question.visibilitySettings.hidePeerResult && reviewType === ReviewerTypes.Peer || question.visibilitySettings.hideUpwardResult && reviewType === ReviewerTypes.Upward;
    if (questionVisibility) {
      return /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { display: "flex", alignItems: "center", gap: spacing.sm, width: "auto" }, children: [
        "Your answer will not be shared with ",
        /* @__PURE__ */ jsx(UserCell, { userId: entry.revieweeId })
      ] });
    }
    if (cycle.visibilitySettings.hidePeerAuthor && reviewType === ReviewerTypes.Peer) {
      return /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { display: "flex", alignItems: "center", gap: spacing.sm, width: "auto" }, children: [
        /* @__PURE__ */ jsx("span", { children: "Your answer will be shared with " }),
        /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(UserCell, { userId: entry.revieweeId }) }),
        /* @__PURE__ */ jsx("span", { children: "without showing your name" })
      ] });
    }
    return /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { display: "flex", alignItems: "center", gap: spacing.sm }, children: [
      "Your answer will be shared with ",
      /* @__PURE__ */ jsx(UserCell, { userId: entry.revieweeId })
    ] });
  }, [cycle, question, reviewType, entry]);
  const getQuestionByType = (question2) => {
    switch (question2.type) {
      case QuestionType.SingleAnswer:
        return /* @__PURE__ */ jsx(
          SingleAnswerQuestion,
          {
            question: question2,
            reviewType,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      case QuestionType.OpenEnded:
        return /* @__PURE__ */ jsx(
          OpenEndedQuestion,
          {
            question: question2,
            reviewType,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      case QuestionType.MultipleAnswer:
        return /* @__PURE__ */ jsx(
          MultipleChoiceQuestion,
          {
            question: question2,
            reviewType,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      case QuestionType.ScaleQuestion:
        return /* @__PURE__ */ jsx(
          ScaleQuestion,
          {
            question: question2,
            reviewType,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      default:
        return null;
    }
  };
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.s2,
        padding: spacing.p16,
        border: borders.background,
        borderRadius: radius.br8
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", dangerouslySetInnerHTML: { __html: questionText != null ? questionText : "" } }),
        showHiddenInformation && /* @__PURE__ */ jsx(
          ChipComponent,
          {
            name: showHiddenInformationTest,
            backgroundColor: "white",
            border: "background",
            style: { width: "fit-content" }
          }
        ),
        getQuestionByType(question)
      ]
    }
  );
};
const ViewItem = ({ label, value }) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "inline-grid",
        gridTemplateColumns: "2fr 2fr",
        rowGap: spacing.g24,
        columnGap: spacing.g4,
        width: "100%"
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: label }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: value })
      ]
    }
  );
};
