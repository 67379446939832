"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/system";
import { keyBy } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { ContractAPI } from "@/api-client/contract.api";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DocumentBig } from "@/images/documents/DocumentBig.svg";
import { ReactComponent as Megaphone } from "@/images/documents/Megaphone.svg";
import { ReactComponent as Upload } from "@/images/fields/Upload.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import { TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, TEMPLATE_CONTRACT_SIGN_ROUTE } from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { BigFilterButton } from "@/v2/components/big-filter-buttons/big-filter-button.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { NewDocumentCategoryFilters } from "@/v2/feature/document-new/components/new-document-category-filters.component";
import { NewDocumentFormModal } from "@/v2/feature/document-new/components/new-document-form-modal.component";
import { NewDocumentPreview } from "@/v2/feature/document-new/components/new-document-preview.component";
import { NewDocumentTable } from "@/v2/feature/document-new/components/new-document-table.component";
import { NewDocumentUploadModal } from "@/v2/feature/document-new/components/new-document-upload-modal.component";
import { NewDocumentViewModal } from "@/v2/feature/document-new/components/new-document-view-modal.component";
import {
  companySignatureRequired,
  recipientSignatureRequired,
  signatoriesRequiredForContract
} from "@/v2/feature/document-new/document-new.util";
import {
  FormTypeEnum,
  UserDocumentView,
  UserDocumentViewArray
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import { stringToDownloadFile, stringToPreviewFile } from "@/v2/feature/documents/documents.util";
import { ContractTemplateManager } from "@/v2/feature/templates/features/contract-template-manager/contract-template-manager.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { UserType } from "@/v2/interfaces/general.interface";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const MemoizedTable = React.memo(NewDocumentTable, (before, after) => {
  return before.documentList === after.documentList && before.loading === after.loading && before.pagination === after.pagination;
});
export const DnUserListingPage = ({
  userId,
  reach,
  userReach,
  customRuleDataset
}) => {
  var _a, _b, _c, _d, _e;
  const [state, globalDipatch] = useContext(GlobalContext);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filterString, setFilterString] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [documentList, setDocumentList] = useState(void 0);
  const [documentTypes, setDocumentTypes] = useState(void 0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [formType, setFormType] = useState(FormTypeEnum.upload);
  const [error, setError] = useState(null);
  const [docData, setDocData] = useState(null);
  const fileURLRef = useRef(null);
  const [viewFilter, setViewFilter] = useState(
    (_d = (_c = (_b = (_a = state.user.features) == null ? void 0 : _a.document) == null ? void 0 : _b.me) == null ? void 0 : _c.selectedFilters) != null ? _d : UserDocumentView.Company
  );
  const [docCounts, setDocCounts] = useState({
    pinnedCount: 0,
    personalCount: 0,
    companyCount: 0
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState(false);
  const [missingFields, setMissingFields] = useState();
  const [selectDocument, setSelectedDocument] = useState(void 0);
  const documentTypesLookup = useMemo(() => keyBy(documentTypes != null ? documentTypes : [], "id"), [documentTypes]);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const isAdmin = canAccessScopes(state.user, ["documents:all"]);
  const setUserDocumentsRecords = useCallback(() => __async(void 0, null, function* () {
    try {
      const documentsResponse = yield DocumentNewAPI.getDocumentsByUserId(
        userId,
        pagination.pageIndex,
        pagination.pageSize,
        filterString,
        searchInput,
        viewFilter
      );
      const { paginatedDocs, pinnedCount, personalCount, companyCount, documentTypes: documentTypes2 } = documentsResponse;
      setDocumentList(paginatedDocs.items);
      setTotalPages(paginatedDocs.totalPages);
      setTotalItems(paginatedDocs.totalItems);
      setDocCounts({ pinnedCount, personalCount, companyCount });
      setDocumentTypes(documentTypes2);
    } catch (error2) {
      showMessage("Something went wrong", "error");
    } finally {
      setLoadingData(false);
    }
  }), [pagination, filterString, searchInput, viewFilter, userId, showMessage]);
  useEffect(() => {
    setLoadingData(true);
    setUserDocumentsRecords().finally(() => {
      setLoadingData(false);
    });
  }, [pagination, filterString, searchInput, viewFilter, userId, setUserDocumentsRecords]);
  const debouncedFilter = useDebouncedCallback((filterString2) => __async(void 0, null, function* () {
    try {
      setFilterString(filterString2);
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error2) {
      showMessage(polyglot.t("DocumentsCompanyPage.errorMessages.filter"), "error");
    }
  }), 500);
  const handleFilter = useCallback(
    (event) => {
      debouncedFilter.callback(event);
    },
    [debouncedFilter]
  );
  const viewOptions = useMemo(() => {
    const counts = {
      [UserDocumentView.Pinned]: docCounts.pinnedCount,
      [UserDocumentView.Personal]: docCounts.personalCount,
      [UserDocumentView.Company]: docCounts.companyCount
    };
    return UserDocumentViewArray.map((view) => /* @__PURE__ */ jsx(
      BigFilterButton,
      {
        title: view,
        action: () => __async(void 0, null, function* () {
          setViewFilter(view);
          setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
          const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures("document", "me", "selectedFilters", view);
          globalDipatch({
            type: GlobalStateActions.UPDATE_USER,
            payload: updatedGlobalUser
          });
        }),
        subtitle: `${counts[view]} documents`,
        active: viewFilter === view,
        loading: !documentList
      }
    ));
  }, [docCounts, viewFilter, globalDipatch, documentList]);
  const handleEdit = useCallback((document) => {
    setIsOpen(true);
    setSelectedDocument(document);
  }, []);
  const handleDelete = useCallback(
    (document) => __async(void 0, null, function* () {
      try {
        yield DocumentNewAPI.deleteDocument(document.id);
        showMessage("Successfully deleted the document", "success");
        setLoadingData(true);
        setUserDocumentsRecords().finally(() => {
          setLoadingData(false);
        });
      } catch (error2) {
        showMessage(nestErrorMessage(error2, polyglot), "error");
      }
    }),
    [polyglot, showMessage, setUserDocumentsRecords]
  );
  const handleUpload = useCallback((document) => {
    setIsUploadOpen(true);
    setSelectedDocument(document);
  }, []);
  const handleCancel = useCallback(
    (document) => __async(void 0, null, function* () {
      try {
        yield DocumentNewAPI.cancelDocument(document.id);
        showMessage("Successfully cancelled the request", "success");
        setLoadingData(true);
        setUserDocumentsRecords().finally(() => {
          setLoadingData(false);
        });
      } catch (error2) {
        showMessage(nestErrorMessage(error2, polyglot), "error");
      }
    }),
    [polyglot, showMessage, setUserDocumentsRecords]
  );
  const handleDownload = useCallback(
    (document, type) => __async(void 0, null, function* () {
      try {
        const response = yield DocumentNewAPI.fetchFileDataById(document.id, type);
        stringToDownloadFile(response.data.data, response.contentType, response.extension, document.name);
      } catch (error2) {
        showMessage(nestErrorMessage(error2, polyglot), "error");
      }
    }),
    [polyglot, showMessage]
  );
  const handlePreview = useCallback(
    (document, type) => __async(void 0, null, function* () {
      try {
        const response = yield DocumentNewAPI.fetchFileDataById(document.id, type);
        const docDataResponse = yield stringToPreviewFile(
          response.data.data,
          response.contentType,
          response.extension,
          document.name
        );
        setDocData(docDataResponse);
        if (docDataResponse && docDataResponse.uri) fileURLRef.current = docDataResponse.uri;
        setIsPreviewOpen(true);
        setError(null);
      } catch (error2) {
        showMessage(nestErrorMessage(error2, polyglot), "error");
      }
    }),
    [polyglot, showMessage]
  );
  const handleRemind = useCallback(
    (document) => __async(void 0, null, function* () {
      try {
        yield DocumentNewAPI.sendReminder(document.id);
        setLoadingData(true);
        setUserDocumentsRecords().finally(() => {
          setLoadingData(false);
        });
      } catch (error2) {
        showMessage(nestErrorMessage(error2, polyglot), "error");
      }
    }),
    [setUserDocumentsRecords, polyglot, showMessage]
  );
  const handleContractSign = useCallback(
    (document) => __async(void 0, null, function* () {
      var _a2, _b2, _c2, _d2, _e2, _f;
      try {
        if (!document.contractId || !document.contract) return;
        const { contract } = document;
        const { userId: userId2 } = state.user;
        const signatoriesRequired = ((_a2 = document.contract) == null ? void 0 : _a2.templateText) ? signatoriesRequiredForContract(document.contract.templateText) : null;
        const isRecipient = userId2 === contract.recipient;
        const isCompanySignatory = userId2 === contract.companySignatory;
        const hasContract = document.contractId;
        if (hasContract && isRecipient) {
          const missingFields2 = yield ContractAPI.getEmployeeFieldsForContractById(document.contractId);
          const hasMissingFields = (_b2 = Object.values(missingFields2)) == null ? void 0 : _b2.some((v) => v === true);
          if (hasMissingFields) {
            setMissingFields(missingFields2);
            setSelectedDocument(document);
            setOpenMissingFieldContractModal(true);
          }
          if (recipientSignatureRequired(document, signatoriesRequired) && !contract.recipientSignatureTimestamp) {
            routerHistory.push(
              generatePath(TEMPLATE_CONTRACT_SIGN_ROUTE, {
                templateId: contract.templateId,
                contractId: contract.id,
                userId: contract.recipient
              })
            );
          }
        } else if (contract.id && isCompanySignatory) {
          if (companySignatureRequired(document, signatoriesRequired) && !contract.companySignatureTimestamp) {
            const stateToPassThrough = {
              companySignatoryUserId: (_c2 = document.contract) == null ? void 0 : _c2.companySignatory
            };
            routerHistory.push(
              generatePath(TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, {
                templateId: (_d2 = document.contract) == null ? void 0 : _d2.templateId,
                contractId: (_e2 = document.contract) == null ? void 0 : _e2.id,
                userId: (_f = document.contract) == null ? void 0 : _f.companySignatory
              }),
              stateToPassThrough
            );
          }
        }
      } catch (error2) {
        showMessage(nestErrorMessage(error2, polyglot), "error");
      }
    }),
    [polyglot, showMessage, routerHistory, state.user]
  );
  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
    if (fileURLRef.current) {
      URL.revokeObjectURL(fileURLRef.current);
      fileURLRef.current = null;
    }
  }, []);
  const handleRowClick = useCallback((row) => {
    setSelectedDocument(row.original);
    setIsViewOpen(true);
  }, []);
  const getDocumentActions = useCallback(() => {
    return [
      {
        handler: () => {
          setFormType(FormTypeEnum.upload);
          setIsOpen(true);
        },
        label: "Upload",
        icon: /* @__PURE__ */ jsx(Upload, __spreadValues({}, iconSize)),
        disabled: false
      },
      {
        handler: () => {
          setFormType(FormTypeEnum.request);
          setIsOpen(true);
        },
        label: "Request",
        icon: /* @__PURE__ */ jsx(Megaphone, __spreadValues({}, iconSize)),
        disabled: false
      },
      {
        handler: () => setOpenTemplateModal(true),
        label: "Template",
        icon: /* @__PURE__ */ jsx(DocumentBig, __spreadValues({}, iconSize)),
        disabled: false,
        hidden: !isAdmin
      }
    ];
  }, [isAdmin]);
  const primaryAction = useMemo(() => {
    if (userReach === UserType.App) {
      return /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          onClick: () => {
            setFormType(FormTypeEnum.upload);
            setIsOpen(true);
          },
          children: "New document"
        }
      );
    }
    return /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: getDocumentActions(),
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: "Actions",
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end"
        }
      }
    );
  }, [userReach, getDocumentActions]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", color: "DarkGrey", children: "Documents" }),
        showAction: true,
        actions: primaryAction
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, sx: { overflow: "hidden" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: viewOptions }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, mt: spacing.s2 }, children: [
        /* @__PURE__ */ jsx(
          NewDocumentCategoryFilters,
          {
            documentTypes: documentTypes != null ? documentTypes : [],
            handleFilter,
            filterString,
            viewFilter
          }
        ),
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              var _a2, _b2;
              setSearchInput((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
              setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
            },
            placeholder: polyglot.t("DocumentsCompanyPage.search")
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
        /* @__PURE__ */ jsx(
          MemoizedTable,
          {
            documentTypesLookup,
            documentList: documentList != null ? documentList : [],
            loading: loadingData,
            pagination,
            setPagination,
            totalPages,
            totalItems,
            handleRowClick,
            actions: {
              onEdit: handleEdit,
              onDelete: handleDelete,
              onCancel: handleCancel,
              onDownload: handleDownload,
              onPreview: handlePreview,
              onRemind: handleRemind,
              onSign: handleContractSign,
              onUpload: handleUpload
            },
            customRuleDataset
          }
        ),
        /* @__PURE__ */ jsx(
          NewDocumentFormModal,
          {
            isOpen,
            setIsOpen,
            onClose: () => {
              setIsOpen(false);
              setIsViewOpen(false);
              setTimeout(() => {
                setSelectedDocument(void 0);
                setFormType(FormTypeEnum.upload);
              }, 300);
            },
            refresh: setUserDocumentsRecords,
            documentTypes,
            document: selectDocument,
            formType,
            userId,
            reach
          }
        ),
        /* @__PURE__ */ jsx(
          NewDocumentUploadModal,
          {
            isOpen: isUploadOpen,
            setIsOpen: setIsUploadOpen,
            onClose: () => {
              setIsUploadOpen(false);
              setSelectedDocument(void 0);
            },
            selectedDocument: selectDocument,
            refresh: setUserDocumentsRecords
          }
        ),
        /* @__PURE__ */ jsx(
          NewDocumentViewModal,
          {
            isOpen: Boolean(isViewOpen && selectDocument),
            setIsOpen: setIsViewOpen,
            onClose: () => {
              setIsViewOpen(false);
              setTimeout(() => {
                setSelectedDocument(void 0);
              }, 300);
            },
            actions: {
              onEdit: handleEdit,
              onDelete: handleDelete,
              onCancel: handleCancel,
              onDownload: handleDownload,
              onPreview: handlePreview,
              onRemind: handleRemind,
              onUpload: handleUpload,
              onSign: handleContractSign
            },
            documentTypes,
            document: selectDocument
          }
        ),
        /* @__PURE__ */ jsx(
          NewDocumentPreview,
          {
            docData,
            onClose: closePreview,
            isOpen: isPreviewOpen,
            error,
            title: (_e = docData == null ? void 0 : docData.fileName) != null ? _e : "Document"
          }
        ),
        /* @__PURE__ */ jsx(React.Suspense, { fallback: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", width: "100%" }), children: /* @__PURE__ */ jsx(
          ContractTemplateManager,
          {
            openTemplateModal,
            setOpenTemplateModal,
            openMissingFieldContractModal,
            setOpenMissingFieldContractModal,
            missingFields,
            setMissingFields
          }
        ) })
      ] })
    ] })
  ] });
};
