"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { ListItem } from "./list-item.component";
import { ChipComponent } from "@/v2/components/chip/chip.component";
export const KeyResultListItem = ({ keyResult }) => {
  return /* @__PURE__ */ jsx(
    Link,
    {
      to: "#",
      title: keyResult.name,
      style: {
        marginTop: "1.25rem",
        width: "100%",
        textDecoration: "none",
        color: "inherit"
      },
      children: /* @__PURE__ */ jsx(
        ListItem,
        {
          progressBar: { progress: 0 },
          details: {
            title: keyResult.name,
            description: keyResult.description ? keyResult.description : /* @__PURE__ */ jsx("br", {})
          },
          endSection: /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(
            ChipComponent,
            {
              name: keyResult.status,
              textVariant: "title4",
              backgroundColor: "white",
              textColor: "TextPrimary",
              border: "black2"
            }
          ) })
        }
      )
    }
  );
};
