"use strict";
import { useMemo } from "react";
export const useProgress = (questions, answerArray) => {
  return useMemo(() => {
    if (!questions || !answerArray) return { progress: 0, answeredRequired: 0, totalRequired: 0 };
    const totalRequiredQuestions = questions.length;
    const totalCommentRequiredQuestions = questions.filter((q) => q.isCommentRequired).length;
    const answeredRequiredQuestions = questions.filter((q) => {
      var _a, _b, _c, _d;
      const answer = answerArray.find((a) => a.questionId === q.id);
      if (!answer) return false;
      if (q.type === "openEnded") {
        return answer && answer.comment && ((_b = (_a = answer.comment) == null ? void 0 : _a.trim()) == null ? void 0 : _b.length) > 0;
      } else {
        return answer && answer.answer && ((_d = (_c = answer.answer) == null ? void 0 : _c.trim()) == null ? void 0 : _d.length) > 0;
      }
    }).length;
    const validComments = questions.filter((q) => {
      var _a, _b;
      if (!q.isCommentRequired) return false;
      const answer = answerArray.find((a) => a.questionId === q.id);
      return answer && answer.comment && ((_b = (_a = answer.comment) == null ? void 0 : _a.trim()) == null ? void 0 : _b.length) > 0;
    }).length;
    const totalAnswered = answeredRequiredQuestions + validComments;
    const grandTotal = totalRequiredQuestions + totalCommentRequiredQuestions;
    return {
      progress: grandTotal > 0 ? Math.round(totalAnswered / grandTotal * 100) : 100,
      answeredRequired: answeredRequiredQuestions,
      totalRequired: totalRequiredQuestions,
      validComments,
      totalCommentsRequired: totalCommentRequiredQuestions
    };
  }, [questions, answerArray]);
};
