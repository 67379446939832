"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { GoalListItem } from "@/v2/feature/growth/goals/components/goal-list-item.component";
import { KeyResultListItem } from "@/v2/feature/growth/goals/components/key-result-list-item.component";
import { GoalEndpoints } from "@/v2/feature/growth/goals/goals.api";
import { KeyResultMutationModal } from "@/v2/feature/growth/key-results/components/key-result-mutation-modal.component";
import { KeyResultEndpoints } from "@/v2/feature/growth/key-results/components/key-results.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@/v2/styles/root.styles";
export const GoalPage = () => {
  const { polyglot } = usePolyglot();
  const params = useParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showMessage] = useMessage();
  const { data: goal, mutate: refreshGoal } = useApiClient(GoalEndpoints.getGoal(params.goalId), {
    suspense: false
  });
  const { data: keyResults, mutate: refreshKeyResults } = useApiClient(
    KeyResultEndpoints.getKeyResults(params.goalId),
    {
      suspense: false
    }
  );
  if (!goal) return null;
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Goal progress" }), sx: { paddingLeft: "1.5rem" } }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, noHorizontalPadding: true, children: [
      /* @__PURE__ */ jsx(
        KeyResultMutationModal,
        {
          goalId: goal.id,
          keyResult: void 0,
          isOpen: isDrawerOpen,
          setIsOpen: setIsDrawerOpen,
          onClose: () => setIsDrawerOpen(false),
          refresh: () => __async(void 0, null, function* () {
            yield refreshKeyResults == null ? void 0 : refreshKeyResults().catch((error) => {
              showMessage(`Error: ${nestErrorMessage(error)}`, "error");
            });
            yield refreshGoal == null ? void 0 : refreshGoal().catch((error) => {
              showMessage(`Error: ${nestErrorMessage(error)}`, "error");
            });
          })
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%" }, children: /* @__PURE__ */ jsx(GoalListItem, { goal, showDescription: true, hoverState: false }) }),
      /* @__PURE__ */ jsxs(
        Box,
        {
          className: "sub-title",
          sx: {
            margin: "1.25rem 0",
            padding: "0 1.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("GoalPage.keyResults") }),
            /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "secondary", sizeVariant: "small", onClick: () => setIsDrawerOpen(true), children: polyglot.t("GoalPage.newKeyResult") })
          ]
        }
      ),
      keyResults == null ? void 0 : keyResults.map((keyResult) => /* @__PURE__ */ jsx(KeyResultListItem, { keyResult }, keyResult.id))
    ] })
  ] });
};
