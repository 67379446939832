"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { EmptyStateBox } from "@v2/components/empty-state-box.component";
import { capitalize } from "lodash";
import { GoalListItem } from "../components/goal-list-item.component";
import { GoalMutationModal } from "../components/goal-mutation-modal.component";
import { GoalStatus } from "../goal.interface";
import { GoalEndpoints } from "../goals.api";
import { GlobalContext } from "@/GlobalState";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { FiltersDrawer } from "@/v2/components/table/filters-drawer.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GoalsListPage = ({ reach }) => {
  const [searchInput, setSearchInput] = useState("");
  const [, dispatch] = useContext(GlobalContext);
  const [filterString, setFilterString] = useState("");
  const { data: goals, mutate: refreshGoals, isLoading: loadingGoals } = useApiClient(
    GoalEndpoints.getGoals(reach, searchInput),
    {
      suspense: false
    }
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Goals" }),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsDrawerOpen(true), children: "New goal" }),
        showAction: Boolean(Array.isArray(goals) && goals.length > 0),
        sx: { padding: "0 1.5rem", display: "flex", justifyContent: "space-between", width: "100%" }
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, noHorizontalPadding: true, children: [
      !loadingGoals && (!goals || goals && goals.length === 0) && !searchInput && !filterString ? /* @__PURE__ */ jsx(
        EmptyStateBox,
        {
          onClick: () => setIsDrawerOpen(true),
          title: "Create first goal",
          subtitle: "Track company, team or individual goals and key results. Review progress and updates from your team.",
          buttonTitle: "New goal"
        }
      ) : /* @__PURE__ */ jsx(
        GoalsList,
        {
          goals: goals != null ? goals : [],
          reach,
          searchInput,
          setSearchInput,
          filterString,
          setFilterString,
          dispatch
        }
      ),
      /* @__PURE__ */ jsx(
        GoalMutationModal,
        {
          goal: void 0,
          isOpen: isDrawerOpen,
          setIsOpen: setIsDrawerOpen,
          reach,
          onClose: () => setIsDrawerOpen(false),
          refresh: () => __async(void 0, null, function* () {
            yield refreshGoals == null ? void 0 : refreshGoals();
          })
        }
      )
    ] })
  ] });
};
const GoalsList = ({
  goals,
  searchInput,
  setSearchInput,
  filterString,
  setFilterString
}) => {
  const searchFilterGoals = useCallback(
    (goal) => {
      if (!searchInput || searchInput.length === 0) return true;
      return goal.name.toLowerCase().includes(searchInput.toLowerCase());
    },
    [searchInput]
  );
  const renderedGoals = useMemo(() => {
    return goals == null ? void 0 : goals.filter(searchFilterGoals).map((goal) => /* @__PURE__ */ jsx(GoalListItem, { goal, showDescription: false }, goal.id));
  }, [goals, searchFilterGoals]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, padding: "0 1.5rem" }, children: [
      /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          },
          debounceSearchWait: 100
        }
      ),
      /* @__PURE__ */ jsx(
        FiltersDrawer,
        {
          filtersOptions: [
            {
              filters: {
                status: Object.values(GoalStatus).map((d) => {
                  return { value: d, label: capitalize(d) };
                })
              }
            }
          ],
          selectedFilters: filterString,
          setSelectedFilters: setFilterString
        }
      )
    ] }),
    renderedGoals
  ] });
};
